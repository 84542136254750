import { SnakePicker } from "@/components";
import { FieldClassifications, RequestStatuses } from "@/enums";
import { IAffiliate, ICompany, IGroup, IField, IRequestForm } from "@/models";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import { RouteNames } from "@/router";
import { affiliateRepository, companyRepository, groupRepository } from "@/repositories";

setInteractionMode("eager");

@Component({
    name: "requestForm",
    components: {
        SnakePicker,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo: {
        title: "Request",
    },
})

export default class RequestForm extends Vue {

    public request: IRequestForm = {
        id: null,
        snakeId: null,
        description: null,
        fields: [],
        newFields: [],
        scopeAffiliates: [],
        scopeCompanies: [],
        scopeGroups: [],
        requestedBy: this.$store.getters["account/userEmail"],
        status: null,
        requestedOn: null,
        includeExternalManagerDependencies: false,
    };

    public searchGreen: string = "";
    public greenLoading: boolean = false;
    public greenFieldList: IField[] = [];

    public searchOrange: string = "";
    public orangeLoading: boolean = false;
    public orangeFieldList: IField[] = [];

    public searchRed: string = "";
    public redLoading: boolean = false;
    public redFieldList: IField[] = [];

    public loading: boolean = false;
    public companiesLoading: boolean = false;
    public affiliatesLoading: boolean = false;
    public groupsLoading: boolean = false;
    public companies: ICompany[] = [];
    public affiliates: IAffiliate[] = [];
    public groups: IGroup[] = [];
    public searchCompanies: string = "";
    public searchAffiliates: string = "";
    public searchGroups: string = "";

    $refs!: {
        form: HTMLFormElement;
        observer: InstanceType<typeof ValidationObserver>;
    };

    @Watch("searchGreen")
    public async onSearchGreenChanged(val: string): Promise<void> {
        this.greenLoading = true;
        const greenFieldList: IField[] = this.greenFields.filter(f => f.name.toLocaleLowerCase().includes(this.searchGreen.toLowerCase()));
        this.greenFieldList = greenFieldList;
        this.greenLoading = false;
    }

    @Watch("searchOrange")
    public async onSearchOrangeChanged(val: string): Promise<void> {
        this.orangeLoading = true;
        this.orangeFieldList = this.orangeFields;
        this.orangeFieldList = this.orangeFieldList.filter(f => f.name.toLowerCase().includes(this.searchOrange.toLocaleLowerCase()));
        this.orangeLoading = false;
    }

    @Watch("searchRed")
    public async onSearchRedChanged(val: string): Promise<void> {
        this.redLoading = true;
        this.redFieldList = this.redFields;
        this.redFieldList = this.redFieldList.filter(f => f.name.toLowerCase().includes(this.searchRed.toLowerCase()));
        this.redLoading = false;
    }

    @Watch("searchCompanies")
    public async onSearchCompaniesChanged(val: string): Promise<void> {
        this.companiesLoading = true;
        this.companies = await companyRepository.search({ searchText: val });
        this.companiesLoading = false;
    }

    @Watch("searchAffiliates")
    public async onSearchAffiliatesChanged(val: string): Promise<void> {
        this.affiliatesLoading = true;
        this.affiliates = await affiliateRepository.search({ searchText: val });
        this.affiliatesLoading = false;
    }

    @Watch("searchGroups")
    public async onSearchGroupsChanged(val: string): Promise<void> {
        this.groupsLoading = true;
        if(val != null && val.length > 1)
        {
            this.groups = await groupRepository.search({ searchText: val });
        }
        this.groupsLoading = false;
    }

    get title(): string {
        if(this.isEditing){
            return "Edit integration";
        }
        else {
            return "Request a new integration";
        }
    }

    get icon(): string {
        if(this.isEditing){
            return "mdi-square-edit-outline";
        }
        else {
            return "mdi-plus-box";
        }
    }

    get isEditing(): boolean {
        return this.request.id != null;
    }

    async mounted(): Promise<void> {
        const requestId = this.$route.params.id;
        this.loading = true;
        try {
            await this.$store.dispatch("app/loadFields");
            if(requestId){
                await this.$store.dispatch("app/loadRequestById", requestId);
                await this.$store.dispatch("app/loadSnakeAppById", this.$store.getters["app/currentRequest"].snakeId);
                const currentRequest = this.$store.getters["app/currentRequest"];
                
                if(currentRequest.scopeGroups != null){
                    this.groups = await groupRepository.searchByIds({ ids: currentRequest.scopeGroups.map(sg => sg.id) });
                }
                
                this.request = {
                    id: currentRequest.id,
                    snakeId: currentRequest.snakeId,
                    description: currentRequest.description,
                    fields: currentRequest.fields.filter(f => f.active === true).map(f => f.id),
                    newFields: currentRequest.fields.filter(f => f.active === false).map(f => f.name),
                    scopeAffiliates: currentRequest.scopeAffiliates?.map(sa => sa.id),
                    scopeCompanies: currentRequest.scopeCompanies?.map(sc => sc.id),
                    scopeGroups: currentRequest.scopeGroups?.map(sg => sg.id),
                    requestedBy: currentRequest.requestedBy,
                    status: currentRequest.status,
                    requestedOn: currentRequest.requestedOn,
                    includeExternalManagerDependencies: currentRequest.includeExternalManagerDependencies,
                };
            }
            this.greenFieldList = this.greenFields;
            this.orangeFieldList = this.orangeFields;
            this.redFieldList = this.redFields;
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }

    async submit(): Promise<void> {

        if (await this.$refs.observer.validate()) {
            const action = this.isEditing ? "updateRequest" : "addRequest";
            const message = this.isEditing ? "updated" : "created";
            this.loading = true;

            if (this.request.newFields.length > 0){
                this.request.status = RequestStatuses.AwaitingNewFieldsApproval;
            }
            else {
                if (this.request.fields.some(el => this.greenFields.map((gf)=> gf.id).includes(el))){
                    this.request.status = RequestStatuses.AwaitingGreenApproval;
                }
                else if (this.request.fields.some(el => this.orangeFields.map((of)=> of.id).includes(el))) {
                    this.request.status = RequestStatuses.AwaitingOrangeApproval;
                }
                else if (this.request.fields.some(el => this.redFields.map((rf)=> rf.id).includes(el))) {
                    this.request.status = RequestStatuses.AwaitingRedApproval;
                }
            }

            await this.$store.dispatch(`app/${action}`, this.request)
                .then(() => {
                    this.$emit("success", `The Request has been successfully ${message}.`);
                    this.$router.push({ name: RouteNames.home });
                }, error => {
                    this.$emit("error", error.response?.data.message);
                });

            this.loading = false;
        } else {
            this.$nextTick(() => {
                const el = this.$el.querySelector(".v-messages.error--text:first-of-type").parentElement.parentElement as HTMLFormElement;
                this.$vuetify.goTo(el);
            });
        }
    }

    public backToHome(): void{
        this.$router.push({ name: RouteNames.home});
    }

    get greenFields(): IField[] {
        const fields: IField[] = this.$store.getters["app/fields"];
        return fields.filter(f => f.classification === FieldClassifications.Green);
    }

    get orangeFields(): IField[] {
        const fields: IField[] = this.$store.getters["app/fields"];
        return fields.filter(f => f.classification === FieldClassifications.Orange);
    }

    get redFields(): IField[] {
        const fields: IField[] = this.$store.getters["app/fields"];
        return fields.filter(f => f.classification === FieldClassifications.Red);
    }
}
